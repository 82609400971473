import React from 'react'
import Logo_ft from "../../Images/Logo_fts.png";

function Footer() {

  return (
    <div className='footerl'>
<div className="container">
<div className='footerl_inner'>
<img src={Logo_ft} alt="" />
<ul className='ft_menu'>
  <li><a href='#'>Rent</a></li>
  <li><a href='#'>Buy</a></li>
  <li><a href='#'>Sell</a></li>
  <li><a href='#'>Manage Property</a></li>
  <li><a href='#'>Resources</a></li>
</ul>
<p className='copyrt'>© Copyright 2024<div><a href="">Privacy Policy</a> & <a href="">Terms of Use</a></div></p>





{/* <p><img src={Logo_ft} alt="" /> Reazy information and partnership here<br></br> 
Follow us: <a href="">Visit us on facebook</a>  <a href="https://www.instagram.com/reazy.com.au/?igshid=MzRlODBiNWFlZA%3D%3D"> 
Visit us on  instagram</a> <br></br><a className='ma_il' href="mailto:contact@reazy.com.au">contact@reazy.com.au</a></p>
<p>© Reazy 2022</p> */}
</div>
</div>  
</div>
  )
}

export default Footer